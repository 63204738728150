* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
main {
  min-height: 80vh;
}

.loading {
  margin-top: 210px;
}
h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

@media (max-width: 900px) {
  h2 {
    font-size: 15px;
    width: 89%;
    margin: auto;
  }
}
